import React from 'react'
import Select from 'react-select'
import { useTable } from 'react-table'

import { DicomServers_dicom_servers } from '../hasura/graphQlQueries/types/DicomServers'
import { EditableCell, Table, Header, HeaderCell, Cell, TextCell, NoResults, Row, isColumn } from '../components/common/Table'
import { Organizations_organizations } from '../hasura/graphQlQueries/types/Organizations'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

// @ts-ignore
import dots from '../lib/images/dots.svg'

const defaultColumn: any = {
  EditableCell,
  TextCell,
}

interface Props {
  columns: any
  data: DicomServers_dicom_servers[]
  deleteDicomServer: (id: number) => void
  isAdmin: boolean
  isSuperAdmin: boolean
  organizations: Organizations_organizations[]
  update: (dicomServer: DicomServers_dicom_servers, columnId: string, value: string | null) => void
}

export default function DicomServersTable(props: Props) {
  const { columns, data, deleteDicomServer, isAdmin, isSuperAdmin, organizations, update } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    deleteDicomServer,
    columns,
    data,
    defaultColumn,
    isAdmin,
    organizations,
    update,
  })

  const options = [{ value: null, label: 'None' }].concat(
    ...organizations.map((o): any => ({
      value: o.id,
      label: `${o.display_name} (${o.primary_email})`,
    }))
  )

  const colSpan = (data: any) => (isColumn(data, 'Practice') ? 2 : 1)

  return (
    <Table className="max-width-1100px" cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, idx: number) => (
              <HeaderCell key={idx} {...column.getHeaderProps()} colSpan={colSpan(column)}>
                <Header>{column.render('Header')}</Header>
              </HeaderCell>
            ))}
            {props.isSuperAdmin && <HeaderCell />}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row)
            const dicomServer: DicomServers_dicom_servers = row.original

            return (
              <Row {...row.getRowProps()}>
                {row.cells.map((cell: any, idx2: number) => {
                  if (cell.column.type === 'input') {
                    return (
                      <Cell key={idx2} {...cell.getCellProps()}>
                        {cell.render('EditableCell')}
                      </Cell>
                    )
                  } else if (cell.column.Header === 'Practice') {
                    return (
                      <Cell key={idx2} {...cell.getCellProps()} colSpan={2}>
                        {props.isSuperAdmin ? (
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(option) => update(dicomServer, 'organization_id', option?.value ? option.value : null)}
                            options={options}
                            value={options.find((o) => o.value === row.values.organization_id)}
                          />
                        ) : (
                          <p className="m-0">{organizations.find((o) => o.id === row.values.organization_id)?.display_name}</p>
                        )}
                      </Cell>
                    )
                  } else {
                    return (
                      <Cell key={idx2} {...cell.getCellProps()}>
                        {cell.render('TextCell')}
                      </Cell>
                    )
                  }
                })}

                {isSuperAdmin && (
                  <Cell>
                    <UncontrolledDropdown className="dropdown-menu-dots" onClick={(e: any) => e.stopPropagation()}>
                      <DropdownToggle caret>
                        <img className="icon-s ml-2 mb-1" src={dots} />
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem header>
                          <p className="m-0 text-s text--gray8">More actions</p>
                        </DropdownItem>

                        <DropdownItem text>
                          <p onClick={() => deleteDicomServer(row.original.id)} className="m-0 text-s regular pointer">
                            Delete
                          </p>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Cell>
                )}
              </Row>
            )
          })
        ) : (
          <NoResults columns={columns?.length} message="No DICOM servers set up." />
        )}
      </tbody>
    </Table>
  )
}
