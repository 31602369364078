import React, { useEffect, useState } from 'react'
import identity from 'lodash/identity'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { useDispatch, useSelector } from 'react-redux'

import DicomServersTable from '../components/DicomServersTable'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import RadioButton from '../components/common/RadioButton'
import { DicomServers_dicom_servers } from '../hasura/graphQlQueries/types/DicomServers'
import { OrganizationEvent } from '../lib/organizationEventTypes'
import { isAdmin, isEnterpriseAdmin, isSuperAdmin } from '../lib/helpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  OrganizationsState,
  fetchOrganizationsAction,
  insertOrganizationEventForUserAction,
  organizationsSelector,
} from '../hasura/slices/organizations'

import {
  DicomServersState,
  deleteDicomServerAction,
  dicomServersSelector,
  fetchDicomServersAction,
  updateDicomServerAction,
} from '../hasura/slices/dicom-servers'

enum DicomServerFilter {
  Unmatched = 'Unmatched',
  All = 'All',
}

export default function DicomSetup() {
  const dispatch = useDispatch()

  const [columns, setColumns] = useState<any | undefined>()
  const [filter, setFilter] = useState<DicomServerFilter>(DicomServerFilter.Unmatched)

  const { accessToken, user, role }: UsersState = useSelector(usersSelector)
  const { dicomServers }: DicomServersState = useSelector(dicomServersSelector)
  const { organizations }: OrganizationsState = useSelector(organizationsSelector)

  /*
    Effects
  */

  useEffect(() => {
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedDicomServers, user?.organization.id))
  }, [])

  useEffect(() => {
    if (!accessToken || !user) return

    if (isSuperAdmin(role)) {
      dispatch(fetchOrganizationsAction(accessToken))
    } else if (isEnterpriseAdmin(role)) {
      dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
    }

    dispatch(fetchDicomServersAction(accessToken))

    setColumns(
      [
        isAdmin(role) && {
          Header: 'Created At',
          accessor: 'created_at',
          type: 'text',
          isDate: true,
        },
        {
          Header: 'AET',
          accessor: 'aet',
          type: 'text',
        },
        {
          Header: 'IP Address',
          accessor: 'ip_address',
          type: 'text',
        },
        isSuperAdmin(role) && {
          Header: 'IP Geocoded',
          accessor: 'ip_address_geocoded',
          type: 'text',
        },
        isAdmin(role) && {
          Header: 'Practice',
          accessor: 'organization_id',
          type: 'text',
        },
        isSuperAdmin(role) && {
          Header: 'First Practice',
          accessor: 'first_study_institution_name',
          type: 'text',
        },
        isSuperAdmin(role) && {
          Header: 'First Patient',
          accessor: 'first_study_patient_name',
          type: 'text',
        },
      ].filter((obj) => obj)
    )
  }, [accessToken, user])

  /*
    Methods
  */

  const data = isSuperAdmin(role)
    ? dicomServers.filter((d) => filter === DicomServerFilter.All || !d.organization_id)
    : isEnterpriseAdmin(role)
    ? dicomServers.filter((d) => d.organization?.enterprise_id === user?.organization.enterprise.id)
    : dicomServers.filter((d) => d.organization_id === user?.organization.id)

  const handleUpdateDicomServer = async (dicomServer: DicomServers_dicom_servers, columnId: string, value: string | null) => {
    let variables = pick(dicomServer, ['id', 'aet', 'ip_address', 'organization_id'])
    // @ts-ignore
    variables[columnId] = value
    // @ts-ignore
    variables = pickBy(variables, identity)
    await dispatch(updateDicomServerAction(accessToken, variables))
    dispatch(fetchDicomServersAction(accessToken))
  }

  const handleDeleteDicomServer = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this DICOM server?')) return

    await dispatch(deleteDicomServerAction(accessToken, id))
    dispatch(fetchDicomServersAction(accessToken))
  }

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-end mb-2">
          <h4 className="bold m-0">DICOM Servers</h4>

          {isSuperAdmin(role) && (
            <div className="d-flex gap-10px ml-4">
              {[DicomServerFilter.All, DicomServerFilter.Unmatched].map((f) => (
                <RadioButton key={f} onClick={() => setFilter(f)} checked={f == filter} label={f} />
              ))}
            </div>
          )}
        </div>

        {user && columns && (
          <DicomServersTable
            columns={columns}
            data={data}
            deleteDicomServer={handleDeleteDicomServer}
            isAdmin={isAdmin(role)}
            isSuperAdmin={isSuperAdmin(role)}
            organizations={organizations || []}
            update={handleUpdateDicomServer}
          />
        )}
      </MainBox>
    </Layout>
  )
}
